import React from "react";

const Skill = ({ name }) => {
  return (
    <div className="rounded-full p-2 bg-indigo-600 text-white mr-2 mb-2">
      <p className="text-xs font-semibold">{name}</p>
    </div>
  );
};

export default Skill;