import React from "react";
import Skill from "./Skill";

const Experience = ({ employer, dates, position, description, skills, url }) => {
  return (
    <div className="flex flex-wrap sm:flex-nowrap space-y-2 sm:space-y-0 sm:space-x-8 rounded-md p-4 cursor-pointer hover:bg-indigo-900" onClick={() => window.open(url, "_blank") }>
      <div className="w-full sm:w-1/3">
        <p className="text-white font-semibold sm:text-2xl">{employer}</p>
        <p className="text-gray-400 sm:text-sm">{dates}</p>
      </div>
      <div className="w-full sm:w-2/3">
        <p className="text-white sm:text-lg">{position}</p>
        <p className="text-gray-400 sm:text-base">{description}</p>
        <div className="flex flex-wrap mt-2">
          {skills.map(skill => {
            return <Skill name={skill} />
          })}
        </div>
      </div>
    </div>
  );
};

export default Experience;