import React from "react";
import Skill from "./Skill";

const Project = ({ name, image, description, url, skills }) => {
  return (
    <div className="flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-8 cursor-pointer p-4 hover:bg-indigo-900 rounded-md" onClick={() => window.open(url, "_blank") }>
      <div className="w-full sm:w-1/4">
        <img src={image} className="sm:w-48 rounded-md" alt="Project" />
      </div>
      <div className="w-full sm:w-2/3">
        <p className="text-white font-semibold sm:text-2xl">{name}</p>
        <p className="text-gray-400 sm:text-base">{description}</p>
        <div className="flex flex-wrap mt-2">
          {skills.map(skill => {
            return <Skill name={skill} />
          })}
        </div>
      </div>
    </div>
  );
};

export default Project;