import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Experience from "../components/Experience";
import Project from "../components/Project";

const Home = () => {
  return (
    <div className="bg-indigo-950 sm:h-screen p-12 sm:p-16 sm:flex">
      <div className="sm:w-1/3">
        <div className="space-y-2">
          <p className="font-bold text-white text-4xl sm:text-5xl">Tommy Adams</p>
          <p className="font-semibold text-white text-lg sm:text-2xl">DevOps Engineer</p>
          <p className="text-gray-400 text-sm sm:text-lg">I build systems that scale and improve the workflow while doing so.</p>
        </div>
        <div className="flex h-3/4 items-end justify-start mt-4 sm:mt-0 mb-16 sm:mb-0">
          <div className="sm:space-y-4">
            <img src="./img/headshot.jpeg" className="hidden sm:block w-20 sm:w-36 rounded-full border border-white" alt="Tommy Adams" />
            <div className="flex space-x-4 justify-center">
              <FaLinkedin className="text-3xl text-white cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/thomas-adams-2001/", "_blank")} />
              <FaGithub className="text-3xl text-white cursor-pointer" onClick={() => window.open("https://github.com/tommy-adams", "_blank")} />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-2/3 sm:pl-48 sm:pr-16 overflow-y-scroll sm:space-y-12">
        <div className="sm:space-y-8">
          <p className="text-gray-400 sm:text-lg">
            In 2024, I graduated with a B.S. in Computer Science from <span className="font-semibold text-white">Stanford University</span>, focusing 
            on computer systems. My studies allowed me to grow my knowledge in <span className="font-semibold text-white">APIs, databases,</span> distributed systems, operating systems, networking, 
            parallel programming, and more. I was also able to explore my passion for <span className="font-semibold text-white">ancient history</span>, achieving a minor in history with a focus on empires.
          </p>
          <p className="text-gray-400 sm:text-lg">
            I've also had the privilege in my early career of working on a <span className="font-semibold text-white">coaching platform</span>, founding 
            a <span className="font-semibold text-white">startup</span>, and working at a <span className="font-semibold text-white">security company</span>. 
            Currently, I am a DevOps engineer at a <span className="font-semibold text-white">civil engineering firm</span>, hoping to expand my knowledge and 
            start my career in building / maintaining complex systems and improving the developer experience.
          </p>
        </div>
        <div className="sm:space-y-8">
          <div className="space-y-2">
            <p className="text-white sm:text-2xl text-right font-semibold">Experiences</p>
            <hr />
          </div>
          <Experience
            position="DevOps Engineer"
            employer="Ware Malcomb"
            dates="Aug. 2024"
            description="Build the backend across multiple projects. Manage the stability and liveness
                         of the platform through Kubernetes while working with AWS for both infrastructure
                         and CI / CD. Lay the foundation for IaC through Terraform."
            skills={["Kubernetes", "Python", "AWS", "Terraform"]}
            url="https://waremalcomb.com/"
          />
          <Experience
            position="Software Engineer Intern"
            employer="Verkada"
            dates="June 2023 – Sept. 2023"
            description="Built a Public API for the Access Control team comprised of 24 endpoints.
                         Designed and created a microservice to handle interactions with Apple Wallet API,
                         serving as a new credential for over 22,000 global organizations. Helped with the
                         overall sharding efforts to globalize the Access Control platform."
            skills={["Kubernetes", "Python", "AWS", "Terraform", "Postgres", "DynamoDB"]}
            url="https://www.verkada.com/"
          />
          <Experience
            position="Cofounding Software Engineer"
            employer="Peach Works"
            dates="April 2022 – Jan. 2023"
            description="Designed and implemented both a Firestore and Postgres database. Built an authentication flow 
                         with Firebase and constructed notification infrastructure with Swift. Designed an analytics API 
                         using Python flask, visualized through Grafana and SQL. Delivered major frontend features
                         in SwiftUI."
            skills={["Swift", "Python", "Firebase", "Postgres", "Google Cloud"]}
            url="https://www.linkedin.com/company/yawchat/"
          />
          <Experience
            position="Software Engineer Intern"
            employer="Dulead"
            dates="June 2019 – Aug. 2021"
            description="Built major features using ReactJS, TailwindCSS, and Ruby on Rails. Refactored the entire
                         user participation experience, affecting 200,000 users. Visualized behavioral analytics
                         using Grafana and SQL."
            skills={["ReactJS", "Ruby on Rails", "Postgres"]}
            url="https://dulead.com/"
          />
        </div>
        <div className="sm:space-y-8">
          <div className="space-y-2">
            <p className="text-white sm:text-2xl text-right font-semibold">Projects</p>
            <hr />
          </div>
          <Project
            name="Moosh"
            image="img/moosh.png"
            description="Moosh was my senior capstone project at Stanford. It is a GPT-powered Spotify playlist
                         curator meant to use your listening history and behaviors in combination with an ingested
                         prompt to curate the best playlist for you in the moment. It is currently in beta, with
                         hopes to be production ready within the year."
            url="https://moosh-weld.vercel.app/"
            skills={["ReactJS", "Python", "Firebase", "Docker", "AWS"]}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;